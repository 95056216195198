/**
 * Sticky Header.
 *
 * @package Schibli;
 * @author  Valentin Zmaranda
 * @license GPL-2.0+
 */

const stickyHeader = ((window, $) => {
	/**
	 * Adjust site inner margin top to compensate for sticky header height.
	 *
	 * @since 2.6.0
	 */

	// eslint-disable-next-line @wordpress/no-unused-vars-before-return,no-unused-vars
	const siteHeader = $('.site-header'),
		siteInner = $('.site-inner');

	let initializer = false;
	const mq = window.matchMedia('(min-width: 320px)');
	let widthChange;
	let windowWidth;
	/* JavaScript Media Queries */
	if (matchMedia) {
		widthChange = (mediaQuery) => {
			return mediaQuery.matches;
		};
		windowWidth = widthChange(mq);
	}

	const fixedWithPrimaryNav = () => {
		if (windowWidth) {
			const siteHeaderHeight = siteHeader.outerHeight(true),
				distanceFromTop = siteInner.offset().top;

			$(window).on('scroll', function() {
				const sticky = $('header.site-header'),
					scroll = $(window).scrollTop();

				if (scroll >= distanceFromTop + 47) {
					if (initializer === false) {
						sticky
							.hide('slow', function() {
								const $header = $(this);
								$header.addClass('fixed');
								setTimeout(function() {
									$header.fadeTo(500, 1);
								}, 320);
							})
							.finish();

						siteInner.css({ marginTop: siteHeaderHeight });
						initializer = true;
					}
				} else if (scroll < distanceFromTop + 47) {
					sticky.removeClass('fixed');
					siteInner.removeAttr('style');
					initializer = false;
				}
			});
		}
	};

	const _moveContentBelowFixedHeader = () => {
		fixedWithPrimaryNav();
	};
	const init = () => {
		_moveContentBelowFixedHeader();
		$(window).resize(() => {
			_moveContentBelowFixedHeader();
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default stickyHeader;
