/* eslint-disable @wordpress/dependency-group */

import $ from 'jquery';
/* eslint-disable @wordpress/dependency-group */
import './inc/responsive-menus';
import stickyHeader from './inc/sticky-header';
import './inc/in-view';
import linkScroll from './inc/smooth-scroll';
import './inc/scroll-top';

$(window).on('load', stickyHeader.init);
setTimeout(linkScroll, 320);
