/* eslint-disable @wordpress/dependency-group */
import ScrollReveal from 'scrollreveal';

const nodeList = document.querySelectorAll('[rel="in-view"]');
const crossBoxLeft = document.querySelectorAll('[rel="cross-box-left"]');
const crossBoxRight = document.querySelectorAll('[rel="cross-box-right"]');

const slideUp = {
	delay: 500,
	useDelay: 'onload',
	reset: true,
	mobile: false,
};

const slideLeft = {
	delay: 500,
	distance: '180%',
	origin: 'left',
	opacity: null,
	useDelay: 'onload',
	reset: true,
	mobile: false,
};

const slideRight = {
	delay: 500,
	distance: '180%',
	origin: 'right',
	opacity: null,
	useDelay: 'onload',
	reset: true,
	mobile: false,
};

ScrollReveal().reveal(nodeList, slideUp);
ScrollReveal().reveal(crossBoxLeft, slideLeft);
ScrollReveal().reveal(crossBoxRight, slideRight);
