export default function() {
	//fix - ACF not render the block id

	const isHome = document.body.classList.contains('home');
	if (isHome) {
		const ids = ['.about-us', '.team', '.latest-news', '.contact-box-map'];
		ids.forEach((item) => {
			if (document.querySelector(item) !== null) {
				switch (item) {
					case '.about-us':
						document
							.querySelector(item)
							.setAttribute('id', 'about');
						break;
					case '.team':
						document.querySelector(item).setAttribute('id', 'team');
						break;
					case '.latest-news':
						document
							.querySelector(item)
							.setAttribute('id', 'media');
						break;
					case '.contact-box-map':
						document
							.querySelector(item)
							.setAttribute('id', 'contact');
						break;
					default:
						return false;
				}
			}
		});
	}

	if (window.location.hash) {
		document.querySelector(window.location.hash).scrollIntoView({
			behavior: 'smooth',
		});
	}

	document.querySelectorAll('a[href*="#"]').forEach((anchor) => {
		anchor.addEventListener('click', function(e) {
			const hashId = this.getAttribute('href').match(/#[^?&\/]*/g);
			// console.warn('isHome && !hashId', isHome && !hashId);
			console.log('click2');
			// if (isHome && !hashId) {
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// 	console.log('click3');
			// }

			// console.log('hash', hashId);
			document.querySelector(hashId).scrollIntoView({
				behavior: 'smooth',
			});
		});
	});
}
